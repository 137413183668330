@import "colors";

.skeleton-loader {
  background: $color-gray-300;
  background: linear-gradient(110deg, $color-gray-300 8%, $color-gray-400 18%, $color-gray-300 33%);
  box-shadow: 0 10px 35px -10px rgba(64, 59, 75, 0.1);
  border-radius: 16px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}

@keyframes shine {
  0% {
    opacity: 0.25;
    background-position-x: 0;
  }
  50% {
    opacity: 0.5;
    background-position-x: -100%;
  }
  100% {
    opacity: 0.25;
    background-position-x: -200%;
  }
}
