@import "colors";

html, body {
  background-color: $color-white;
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

*:not(input):not(textarea), *:focus:not(input):not(textarea) {
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline-style: none;
}

*:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}
