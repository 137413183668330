@import "colors";

$spacer: 1rem !default;

$spacers: () !default;

$spacers: map-merge((
        0: 0,
        1: ($spacer * 0.25),
        2: ($spacer * 0.5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3),
        20: 20px,
), $spacers);

$primary: $color-primary;
$secondary: $color-secondary;
