@font-face {
  font-family: 'joo-icons-400';
  src: url('joo-icons-400.woff?oa78mi') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'joo-icons-900';
  src: url('joo-icons-900.woff?x8f06q') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

.ji {
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="jir-"], [class*=" jir-"] {
  font-family: 'joo-icons-400' !important;
}

.jir-pen:before {
  content: "\e901";
}

.jir-arrow-down-to-bracket:before {
  content: "\e902";
}

.jir-check:before {
  content: "\e903";
}

.jir-chevron-left:before {
  content: "\e904";
}

.jir-chevron-right:before {
  content: "\e905";
}

.jir-circle-info:before {
  content: "\e906";
}

.jir-clock:before {
  content: "\e907";
}

.jir-line-up:before {
  content: "\e908";
}

.jir-lock:before {
  content: "\e909";
}

.jir-rotate-right:before {
  content: "\e90a";
}

.jir-send-backward:before {
  content: "\e90b";
}

.jir-sliders:before {
  content: "\e90c";
}

.jir-trophy:before {
  content: "\e90d";
}

.jir-users:before {
  content: "\e90e";
}

.jir-xmark:before {
  content: "\e90f";
}

[class^="jis-"], [class*=" jis-"] {
  font-family: 'joo-icons-900' !important;
}

.jis-play:before {
  content: "\e900";
}
