@import "colors";

.modal-backdrop.show {
  opacity: 0.4 !important;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: opacity 0.3s ease-in-out;
  transform: none;
}

.modal {
  &-blur {
    backdrop-filter: blur(6px);
  }

  &-padding-bottom {
    padding-bottom: 56px;
  }

  &-content {
    pointer-events: auto;
    background-color: $card-background-color;
    border: none !important;
    border-radius: 20px !important;
    outline: 0;
  }

  &-dialog {
    margin: 20px;
    max-width: 100% !important;

    &-centered {
      min-height: calc(100% - 40px);
    }

    &-bottom {
      display: flex;
      align-items: flex-end;
      margin: 0 !important;
      height: 100% !important;

      .modal-content {
        border-radius: 20px 20px 0 0 !important;
      }
    }

    &.modal-full-screen {
      max-width: unset;
      width: 100vw;
      height: 100vh;
      margin: 0;
      background-color: $color-white;
    }
  }

  &-open {
    overflow: hidden !important;
  }
}
