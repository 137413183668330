@import "theme";

// static
$color-primary: #00A9F4;
$color-secondary: #8f2eff;
$color-green: #7DC322;
$color-yellow: #FDC707;
$color-red: #ed1818;

// theme based
$color-black: color("black");
$color-white: color("white");
$color-gray-100: color("gray-100");
$color-gray-150: color("gray-150");
$color-gray-200: color("gray-200");
$color-gray-300: color("gray-300");
$color-gray-350: color("gray-350");
$color-gray-400: color("gray-400");
$html-background-color: color("html-background-color");
$card-background-color: color("card-background-color");
$package-background-color: color("package-background-color");

$color-black-rgb: color("black-rgb");
$color-white-rgb: color("white-rgb");

.color-red {
  color: $color-red;
}

$color-primary-8: rgb($color-primary, 8%);
$color-secondary-8: rgb($color-secondary, 8%);
$color-green-8: rgb($color-green, 8%);
$color-red-8: rgb($color-red, 8%);
$color-yellow-8: rgb($color-yellow, 8%);
