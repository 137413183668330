@font-face {
  font-family: Inter;
  src: url('inter-400.woff2');
  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url('inter-500.woff2');
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url('inter-600.woff2');
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url('inter-700.woff2');
  font-weight: 700;
}
