@import "bootstrap-override";
@import "bootstrap/scss/bootstrap";

@import "reset";
@import "colors";
@import "button";
@import "modal";
@import "page";
@import "card";
@import "skeleton-loader";

// TODO: need to move below styles into separate files

.c-orange {
  color: $color-primary;
}

.text-mathjax-html {
  opacity: 0;

  * {
    font-size: unset !important;
    opacity: 0;
    transition: 0.25s;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  &.shrink-loading {
    max-height: 24px;
    overflow: hidden;
    transition: 0.25s;
  }

  &.loaded {
    opacity: 1;

    * {
      opacity: 1;
    }

    &.shrink-loading {
      max-height: 999px;
    }
  }

  p:last-child {
    margin-bottom: 0 !important;
  }

  img {
    width: 100% !important;
    object-fit: contain !important;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.nowrap {
  white-space: nowrap;
}

