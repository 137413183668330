@import "colors";

.card {
  position: unset;
  display: block;
  flex-direction: unset;
  border: none;
  background: $card-background-color;
  box-shadow: 0 10px 35px -10px rgba(64, 59, 75, 0.1);
  border-radius: 16px;

  &.transparent {
    opacity: .5;
  }

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }
}
