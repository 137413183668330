@import "colors";

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding: 16px 20px;

  &-sm {
    border-radius: 8px;
    font-size: 12px;
    line-height: 140%;
    padding: 4px 10px;
  }

  &-primary {
    background-color: $color-primary !important;
    color: #fff !important;
  }

  &-outline-primary {
    background-color: rgba($color-primary, 8%) !important;
    color: $color-primary !important;

    &:active {
      background-color: transparent !important;
    }
  }

  &-black {
    background-color: $color-black !important;
    color: $color-white !important;
  }

  &-secondary {
    background-color: $color-secondary !important;
    color: #fff !important;
  }

  &-outline-secondary {
    background-color: transparent !important;
    color: $color-secondary !important;
  }

  &-light {
    background-color: $color-gray-400 !important;
    color: $color-black !important;
  }

  &-green {
    background-color: $color-green !important;
    color: #fff !important;
  }

  &.disabled {
    pointer-events: none;
    background-color: $color-gray-300 !important;
    border-color: $color-gray-300 !important;
    color: #fff !important;
  }
}
