$colors: (
        black: #151c22,
        white: #ffffff,
        gray-100: #4B4B4B,
        gray-150: #868686,
        gray-200: #B5B5B5,
        gray-300: #E4E4E4,
        gray-350: #ECECEC,
        gray-400: #F5F5F5,
        html-background-color: #fbfbfb,
        card-background-color: #ffffff,
        package-background-color: #F3F5F6,
        black-rgb: (21, 28, 34),
        white-rgb: (255, 255, 255),
);

$colors-dark: (
        black: #ffffff,
        white: #151c22,
        gray-100: #F5F5F5,
        gray-150: #ECECEC,
        gray-200: #E4E4E4,
        gray-300: #B5B5B5,
        gray-350: #888,
        gray-400: #4B4B4B,
        html-background-color: #151c22,
        card-background-color: #2a2624,
        package-background-color: #4B4B4B,
        black-rgb: (255, 255, 255),
        white-rgb: (21, 28, 34),
);

:root {
  @each $name, $color in $colors {
    --color-#{"" + $name}: #{$color};
  }
  --icon-filter: invert(0%);
  --theme: "light";
}

:root.dark {
  @each $name, $color in $colors-dark {
    --color-#{"" + $name}: #{$color};
  }
  --icon-filter: invert(100%);
  --theme: "dark";
}

@function color($color-name) {
  @return var(--color-#{$color-name});
}

@function gradient($color-start, $color-end, $deg: 180deg, $color-start-per: 0%, $color-end-per: 100%) {
  @return linear-gradient($deg, $color-start $color-start-per, $color-end $color-end-per);
}
