@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Regular.woff2);
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Medium.woff2);
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Medium.woff2);
  font-weight: 600;
}

@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Bold.woff2);
  font-weight: 700;
}
